<template>
    <el-tooltip v-if="lastContacted" placement="top">
        <div slot="content">
            <span v-for="(contact, index) in contacted" :key="index">
                &bull;
                {{ contact.date.toDate() | dateformat('MMM D, YYYY') }}
                <br />
            </span>
        </div>
        <div class="contact-cell">
            <span>
                {{ lastContacted | dateformat('MMM D, YYYY') }}
            </span>
            <i v-if="lastContactedToday" class="el-icon-check" />
            <i
                v-else
                class="el-icon-message contact-button"
                @click.stop="handleClickContact"
            />
        </div>
    </el-tooltip>
    <div v-else class="contact-cell">
        <span class="empty">-</span>
        <i
            class="el-icon-message contact-button"
            @click.stop="handleClickContact"
        />
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'user-contact-cell',
    props: {
        contacted: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        lastContacted() {
            return this.contacted?.length
                ? this.contacted[0].date.toDate()
                : null;
        },
        lastContactedToday() {
            return (
                this.lastContacted &&
                moment(this.lastContacted).isSame(moment(), 'day')
            );
        },
    },
    methods: {
        handleClickContact() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-cell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.contact-button {
            cursor: pointer;
            border-radius: 4px;
            &:hover {
                background: rgba(0, 0, 0, 0.1);
                color: $purple-rain;
            }
        }
    }
    .empty {
        width: 100%;
        text-align: center;
    }
}
</style>
