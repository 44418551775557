<template>
    <div class="user-row" @click="$emit('click')">
        <div class="user-row-name">{{ name }}</div>

        <div class="user-row-code">{{ code }}</div>

        <div v-if="show_last_contacted" class="user-row-contact">
            <user-contact-cell
                :contacted="contactedCore"
                @click="logContact('core')"
            />
        </div>

        <div v-if="show_last_contacted" class="user-row-contact">
            <user-contact-cell
                :contacted="contactedIDL"
                @click="logContact('idl')"
            />
        </div>

        <div v-if="show_last_claim" class="user-row-date">
            {{ lastClaim | dateformat('MMM D, YYYY') }}
        </div>

        <div v-if="show_details" class="user-row-plan">
            <el-tag
                v-if="activePlanDays === false"
                :disable-transitions="true"
                :type="'info'"
            >
                <i class="el-icon-loading"></i>
            </el-tag>
            <el-tag
                v-else-if="activePlanDays === null"
                :disable-transitions="true"
                type="warning"
            >
                No active plan
            </el-tag>
            <el-tag
                v-else
                :disable-transitions="true"
                :type="activePlanDays > 30 ? 'info' : 'danger'"
            >
                {{ activePlanLabel }}
            </el-tag>
        </div>

        <div v-if="show_details" class="user-row-budget">
            <div
                v-if="activePlanDays !== false && activePlanDays !== null"
                class="budgetCol"
            >
                <el-tag
                    v-if="activePlan && activePlan.budgetTotal >= 0"
                    :disable-transitions="true"
                    :type="budgetWarning"
                >
                    {{ activePlan.budgetTotal | currency }}
                </el-tag>
                <template v-else>
                    <el-tag
                        v-if="budgetAvailable === false"
                        :disable-transitions="true"
                        :type="'info'"
                    >
                        <i class="el-icon-loading"></i>
                    </el-tag>
                    <el-tag
                        v-if="budgetAvailable === null"
                        :disable-transitions="true"
                        :type="'warning'"
                    >
                        No budgets
                    </el-tag>
                    <el-tag
                        v-if="typeof budgetAvailable == 'number'"
                        :disable-transitions="true"
                        :type="budgetAvailable > 0 ? 'info' : 'danger'"
                    >
                        {{ budgetAvailable | currency }}
                    </el-tag>
                </template>
            </div>
        </div>

        <div v-if="show_details" class="user-row-profile">
            <transition name="fade">
                <el-button
                    v-if="showClaimButton"
                    type="primary"
                    @click.stop="newClaim"
                >
                    Add claim
                </el-button>
            </transition>
        </div>

        <div v-if="selectable" class="user-row-selection">
            <span @click.stop
                ><el-checkbox
                    v-model="isSelected"
                    @change="$emit('select', isSelected)"
            /></span>
        </div>
    </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import UserContactCell from '@/views/components/UserContactCell.vue';

export default {
    name: 'user-row',
    components: {
        UserContactCell,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        show_details: {
            type: Boolean,
            default: true,
        },
        show_last_claim: {
            type: Boolean,
            default: false,
        },
        show_last_contacted: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            /* plans, budgets */
            // map of reference IDs
            // populated with snapshots once loaded
            plans: {},
            budgets: {},
            /* activePlan */
            // false: plans not all loaded
            // null: no active plan
            // value: plan
            activePlan: false,
            budgetAvailable: false,
            isSelected: false,
        };
    },
    computed: {
        name() {
            return this.user.name;
        },
        code() {
            if (!this.user.code) return null;
            return this.user.code.toUpperCase();
        },
        activePlanDays() {
            if (this.activePlan) {
                // find remaining days
                return this.$options.filters.daysDiff(
                    this.$options.filters.timestamp2Date(
                        this.activePlan.expiry.seconds
                    )
                );
            } else {
                // either no plan or not loaded
                return this.activePlan;
            }
        },
        activePlanLabel() {
            let days = this.activePlanDays;
            if (days == 0) {
                return 'Expires today';
            } else if (days < 0) {
                days = Math.abs(days);
                return `Expired ${days} day${days == 1 ? '' : 's'}`;
            } else {
                return `${days} day${days == 1 ? '' : 's'} left`;
            }
        },
        showClaimButton() {
            return (
                this.activePlanDays !== null &&
                this.activePlanDays >= 0 &&
                this.budgetAvailable !== false &&
                this.budgetAvailable !== null
            );
        },
        budgetWarning() {
            // when displaying precalculated budget totals, determines the warning level to display
            if (this.activePlan && this.activePlan.budgetTotal < 500)
                return 'danger';
            if (this.activePlan && this.activePlan.budgetTotal < 1000)
                return 'warning';
            return 'info';
        },
        lastClaim() {
            return this.user.last_claim;
        },
        contactedCore() {
            return this.user.contacted
                ?.filter(
                    (contact) =>
                        contact.plan.id === this.activePlan.id &&
                        contact.type === 'core'
                )
                .sort((a, b) => {
                    return moment(b.date.toDate()).diff(a.date.toDate());
                });
        },
        contactedIDL() {
            return this.user.contacted
                ?.filter((contact) => {
                    return (
                        contact.plan.id === this.activePlan.id &&
                        contact.type === 'idl'
                    );
                })
                .sort((a, b) => {
                    return moment(b.date.toDate()).diff(a.date.toDate());
                });
        },
    },
    watch: {
        user: {
            handler: function (newval, oldval) {
                this.getPlans();
            },
            //deep: true,
        },
        selected: {
            handler: function (val) {
                this.isSelected = val;
            },
            immediate: true,
        },
    },
    mounted() {
        if (this.show_details) {
            this.getPlans();
        }
    },
    methods: {
        async getPlans() {
            if (this.user.plans && this.user.plans.length > 0) {
                this.user.plans.forEach((plan) => {
                    if (typeof plan === 'string' || plan instanceof String) {
                        // reference was passed, load plan
                        this.plans[plan] = plan;
                        this.$fire
                            .doc(plan)
                            .get()
                            .then((snapshot) => {
                                // plan has loaded
                                this.plans['plans/' + snapshot.id] =
                                    snapshot.data();

                                // if not yet set, each time a plan loads check for a current plan
                                if (this.activePlan === false) {
                                    const plans = Object.values(this.plans);
                                    this.activePlan =
                                        this.$options.filters.getActivePlan(
                                            plans
                                        );
                                    this.getBudgets();
                                }
                            })
                            .catch((e) => {
                                console.log('Error loading plans', e);
                                this.$notify.error({
                                    title: 'Error',
                                    message: 'Unable to load plans',
                                });
                                Sentry.captureException(e);
                            });
                    } else {
                        // plan data has been preloaded
                        this.plans['plans/' + plan.id] = {...plan};
                        this.activePlan = plan;
                        this.getBudgets();
                    }
                });
            } else {
                this.activePlan = null;
            }
        },
        async getBudgets() {
            if (this.activePlan) {
                if (this.activePlan.data) {
                    this.activePlan.data.forEach((data) => {
                        if (!data.budget.get) {
                            data.budget = this.$fire.doc(data.budget);
                        }
                        this.budgets[data.budget.id] = false;

                        data.budget.get().then((snapshot) => {
                            this.budgets[snapshot.id] = snapshot.data();
                            // check if all budgets have now been loaded
                            const budgets = Object.values(this.budgets);
                            if (!budgets.includes(false)) {
                                this.budgetAvailable = budgets.reduce(
                                    (a, b) =>
                                        +a +
                                        +(b.budget_allocated - b.budget_spent),
                                    0
                                );
                            }
                        });
                    });
                } else {
                    this.budgetAvailable = null;
                }
            }
        },

        newClaim() {
            this.$emit('new-claim', this.user, this.activePlan);
        },

        logContact(type) {
            this.$emit('contact', this.user, type);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-row {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f2f5;
    background-color: white;
    transition: background-color 0.125s linear;
    cursor: pointer;
    &:hover {
        background-color: #f5f7fa;
    }

    > * {
        &:first-child {
            flex-grow: 1;
        }

        display: flex;
        align-items: center;
        border-right: 1px solid #f0f2f5;
        height: 100%;
        min-width: 100px;
        padding: 10px;
        color: #606266;
        font-size: 14px;
    }

    .user-row-code {
        width: 100px;
    }

    .user-row-date {
        width: 120px;
    }

    .user-row-plan,
    .user-row-budget {
        width: 150px;
    }

    .user-row-contact {
        width: 140px;
    }

    .user-row-profile {
        width: 150px;

        > * {
            width: 100%;
        }
    }

    .user-row-selection {
        width: 40px;
        min-width: 40px;
        justify-content: center;
    }

    .budgetCol {
        width: 100%;
    }
}

.el-tag {
    width: 100%;
    text-align: center;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
