var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-list-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "user-list",
        class: { scroll: _vm.scroll },
        style: _vm.css,
      },
      [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "header-col name" }, [_vm._v("Name")]),
          _c("div", { staticClass: "header-col code" }, [_vm._v("Code")]),
          _vm.show_last_contacted
            ? _c("div", { staticClass: "header-col contact" }, [
                _vm._v(" Last contact (core) "),
              ])
            : _vm._e(),
          _vm.show_last_contacted
            ? _c("div", { staticClass: "header-col contact" }, [
                _vm._v(" Last contact (IDL) "),
              ])
            : _vm._e(),
          _vm.show_last_claim
            ? _c("div", { staticClass: "header-col date" }, [
                _vm._v(" Last claim "),
              ])
            : _vm._e(),
          _vm.show_details
            ? _c("div", { staticClass: "header-col tag" }, [
                _vm._v(" " + _vm._s(_vm.plan_heading) + " "),
              ])
            : _vm._e(),
          _vm.show_details
            ? _c("div", { staticClass: "header-col tag" }, [
                _vm._v(" Available budget "),
              ])
            : _vm._e(),
          _vm.show_details
            ? _c("div", { staticClass: "header-col action" })
            : _vm._e(),
          _vm.selectable
            ? _c(
                "div",
                { staticClass: "header-col selection" },
                [
                  _c("el-checkbox", {
                    attrs: {
                      disabled: !_vm.users || !_vm.users.length,
                      indeterminate:
                        _vm.selectedUsers.length > 0 &&
                        _vm.selectedUsers.length < _vm.users.length,
                    },
                    on: { change: _vm.toggleSelectUsers },
                    model: {
                      value: _vm.allUsersSelected,
                      callback: function ($$v) {
                        _vm.allUsersSelected = $$v
                      },
                      expression: "allUsersSelected",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.users.length
          ? _c(
              "div",
              { staticClass: "user-list-body" },
              _vm._l(_vm.users, function (user) {
                return _c("user-row", {
                  key: user.id,
                  attrs: {
                    user: user,
                    show_expired: _vm.show_expired,
                    show_details: _vm.show_details,
                    show_last_claim: _vm.show_last_claim,
                    show_last_contacted: _vm.show_last_contacted,
                    selectable: _vm.selectable,
                    selected: _vm.isUserSelected(user),
                  },
                  on: {
                    click: () => _vm.handleClickUser(user),
                    select: (selected) => _vm.handleSelectUser(user, selected),
                    "new-claim": _vm.newClaim,
                    contact: _vm.logContactForUser,
                  },
                })
              }),
              1
            )
          : _c("div", { staticClass: "no-users" }, [_vm._v("No data")]),
      ]
    ),
    _vm.scroll
      ? _c("div", { staticClass: "user-list-footer" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("pluralize")(_vm.users.length, " user")) +
              " found "
          ),
        ])
      : _c(
          "div",
          { staticClass: "page-nav" },
          [
            _c("el-button", {
              attrs: { icon: "el-icon-arrow-left", circle: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("prev")
                },
              },
            }),
            _c("el-button", {
              attrs: { icon: "el-icon-arrow-right", circle: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("next")
                },
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }