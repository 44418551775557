var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "user-row",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("div", { staticClass: "user-row-name" }, [_vm._v(_vm._s(_vm.name))]),
      _c("div", { staticClass: "user-row-code" }, [_vm._v(_vm._s(_vm.code))]),
      _vm.show_last_contacted
        ? _c(
            "div",
            { staticClass: "user-row-contact" },
            [
              _c("user-contact-cell", {
                attrs: { contacted: _vm.contactedCore },
                on: {
                  click: function ($event) {
                    return _vm.logContact("core")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.show_last_contacted
        ? _c(
            "div",
            { staticClass: "user-row-contact" },
            [
              _c("user-contact-cell", {
                attrs: { contacted: _vm.contactedIDL },
                on: {
                  click: function ($event) {
                    return _vm.logContact("idl")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.show_last_claim
        ? _c("div", { staticClass: "user-row-date" }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("dateformat")(_vm.lastClaim, "MMM D, YYYY")) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.show_details
        ? _c(
            "div",
            { staticClass: "user-row-plan" },
            [
              _vm.activePlanDays === false
                ? _c(
                    "el-tag",
                    { attrs: { "disable-transitions": true, type: "info" } },
                    [_c("i", { staticClass: "el-icon-loading" })]
                  )
                : _vm.activePlanDays === null
                ? _c(
                    "el-tag",
                    { attrs: { "disable-transitions": true, type: "warning" } },
                    [_vm._v(" No active plan ")]
                  )
                : _c(
                    "el-tag",
                    {
                      attrs: {
                        "disable-transitions": true,
                        type: _vm.activePlanDays > 30 ? "info" : "danger",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.activePlanLabel) + " ")]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.show_details
        ? _c("div", { staticClass: "user-row-budget" }, [
            _vm.activePlanDays !== false && _vm.activePlanDays !== null
              ? _c(
                  "div",
                  { staticClass: "budgetCol" },
                  [
                    _vm.activePlan && _vm.activePlan.budgetTotal >= 0
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              "disable-transitions": true,
                              type: _vm.budgetWarning,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.activePlan.budgetTotal)
                                ) +
                                " "
                            ),
                          ]
                        )
                      : [
                          _vm.budgetAvailable === false
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    "disable-transitions": true,
                                    type: "info",
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-loading" })]
                              )
                            : _vm._e(),
                          _vm.budgetAvailable === null
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    "disable-transitions": true,
                                    type: "warning",
                                  },
                                },
                                [_vm._v(" No budgets ")]
                              )
                            : _vm._e(),
                          typeof _vm.budgetAvailable == "number"
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    "disable-transitions": true,
                                    type:
                                      _vm.budgetAvailable > 0
                                        ? "info"
                                        : "danger",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(_vm.budgetAvailable)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.show_details
        ? _c(
            "div",
            { staticClass: "user-row-profile" },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.showClaimButton
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.newClaim.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Add claim ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectable
        ? _c("div", { staticClass: "user-row-selection" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("el-checkbox", {
                  on: {
                    change: function ($event) {
                      return _vm.$emit("select", _vm.isSelected)
                    },
                  },
                  model: {
                    value: _vm.isSelected,
                    callback: function ($$v) {
                      _vm.isSelected = $$v
                    },
                    expression: "isSelected",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }