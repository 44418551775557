<template>
    <div class="user-list-wrapper">
        <div class="user-list" :class="{scroll}" :style="css">
            <div class="header">
                <div class="header-col name">Name</div>
                <div class="header-col code">Code</div>
                <div v-if="show_last_contacted" class="header-col contact">
                    Last contact (core)
                </div>
                <div v-if="show_last_contacted" class="header-col contact">
                    Last contact (IDL)
                </div>
                <div v-if="show_last_claim" class="header-col date">
                    Last claim
                </div>
                <div v-if="show_details" class="header-col tag">
                    {{ plan_heading }}
                </div>
                <div v-if="show_details" class="header-col tag">
                    Available budget
                </div>
                <div v-if="show_details" class="header-col action"></div>
                <div v-if="selectable" class="header-col selection">
                    <el-checkbox
                        v-model="allUsersSelected"
                        :disabled="!users || !users.length"
                        :indeterminate="
                            selectedUsers.length > 0 &&
                            selectedUsers.length < users.length
                        "
                        @change="toggleSelectUsers"
                    />
                </div>
            </div>
            <div v-if="users.length" class="user-list-body">
                <user-row
                    v-for="user in users"
                    :key="user.id"
                    :user="user"
                    :show_expired="show_expired"
                    :show_details="show_details"
                    :show_last_claim="show_last_claim"
                    :show_last_contacted="show_last_contacted"
                    :selectable="selectable"
                    :selected="isUserSelected(user)"
                    @click="() => handleClickUser(user)"
                    @select="(selected) => handleSelectUser(user, selected)"
                    @new-claim="newClaim"
                    @contact="logContactForUser"
                />
            </div>
            <div v-else class="no-users">No data</div>
        </div>

        <div v-if="scroll" class="user-list-footer">
            {{ users.length | pluralize(' user') }} found
        </div>
        <div v-else class="page-nav">
            <el-button
                icon="el-icon-arrow-left"
                circle
                @click="$emit('prev')"
            />
            <el-button
                icon="el-icon-arrow-right"
                circle
                @click="$emit('next')"
            />
        </div>
    </div>
</template>

<script>
import UserRow from '@/views/components/UserRow';

export default {
    name: 'user-list',
    components: {
        UserRow,
    },
    props: {
        users: {
            type: Array,
            required: true,
        },
        nav_content: {
            type: String,
            default: null,
        },
        show_details: {
            type: Boolean,
            default: true,
        },
        plan_heading: {
            type: String,
            default: 'Active plan',
        },
        show_expired: {
            type: Boolean,
            default: false,
        },
        show_last_claim: {
            type: Boolean,
            default: false,
        },
        show_last_contacted: {
            type: Boolean,
            default: false,
        },
        scroll: Boolean,
        css: {
            type: Object,
            required: false,
            default: null,
        },
        selectable: Boolean,

        new_window: Boolean,
    },
    data() {
        return {
            selectedUsers: [],
            allUsersSelected: false,
        };
    },
    watch: {
        selectedUsers(val) {
            if (val && val.length === this.users.length) {
                this.allUsersSelected = true;
            } else {
                this.allUsersSelected = false;
            }
            this.$emit('select', val);
        },
    },
    methods: {
        newClaim(user, activePlan) {
            this.$emit('new-claim', user, activePlan);
        },

        handleClickUser(user) {
            if (this.new_window) {
                const routeData = this.$router.resolve(`/user/${user.id}`);
                window.open(`${routeData.href}?view=single`, '_blank', 'popup');
            } else {
                this.$emit('open', user.id);
                this.$router.push(`/user/${user.id}`);
            }
        },

        isUserSelected(user) {
            return this.selectedUsers.includes(user.id);
        },

        handleSelectUser(user, selected) {
            if (selected && !this.isUserSelected(user)) {
                this.selectedUsers.push(user.id);
            } else if (!selected && this.isUserSelected(user)) {
                this.selectedUsers = this.selectedUsers.filter(
                    (u) => u !== user.id
                );
            }
        },

        toggleSelectUsers(selected) {
            if (selected) {
                this.selectedUsers = this.users.map((u) => u.id);
            } else {
                this.selectedUsers = [];
            }
        },

        logContactForUser(user, type) {
            this.$emit('contact', user.id, type);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-list {
    background: white;
    min-height: 300px;

    .header {
        display: flex;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #f0f2f5;
        justify-content: center;

        .header-col {
            display: flex;
            align-items: center;
            border-right: 1px solid #f0f2f5;
            padding: 10px;
            font-size: 14px;
            color: #909399;
            font-weight: 600;

            &.name {
                flex-grow: 1;
            }

            &.code {
                width: 100px;
            }

            &.tag,
            &.action {
                width: 150px;
            }
            &.date {
                width: 120px;
            }
            &.contact {
                width: 140px;
            }

            &.selection {
                width: 40px;
                justify-content: center;
            }
        }
    }

    &.scroll {
        position: relative;
        .user-list-body {
            overflow-y: auto;
            height: calc(100% - 50px);
            padding-bottom: 50px;
            position: relative;
        }
        &::after {
            content: '';
            height: 80px;
            width: 100%;
            pointer-events: none;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            bottom: 0;
        }
    }
}

.user-list-footer {
    opacity: 0.75;
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
}

.page-nav {
    padding: 10px;
    text-align: center;
    .nav-content {
        display: inline-block;
        color: $blue;
        padding: 0 10px;
        min-width: 80px;
    }
}

.no-users {
    width: 100%;
    line-height: 50px;
    color: #606266;
    text-align: center;
}
</style>
